@import "ClientApp/styles/base/base.scss";
.layout-ups {
  background-color: var(--layout-ups-background-color);
  width: 100%;
  padding: 0;
  grid-area: usp;
  gap: var(--padding-small);
  display: grid;

  .layout-ups-left {
    .container {
      padding: 0 0 0 var(--padding-small);
    }
  }

  .layout-ups-right {
    .container {
      padding: 0 var(--padding-small) 0 0;
    }
  }
}

.admin-mode {
  .layout-ups {
    .container {
      padding: 0;
      margin: 0;
      .droptarget {
        padding: 0;
        margin: 0 0 10px 0;
      }
    }
  }
}
